if ($('#usuarios').length > 0) {
    new Vue({
        el: '#usuarios',

        data: {
            usuarios: usuarios,
            crear: { nombre: '', tipo: '', email: '', password: '' },
            editar: { _method: 'PUT', id: '', nombre: '', tipo: '', email: '', password: '' },
            eliminar: { _method: 'DELETE', id: '' },
            temporal: {},
            buscar: ''
        },

        methods: {
            crearUsuario: function () {
                var self = this;
                ajax('/plataforma/configuracion/usuarios/almacenar', 'json', self.crear, $('#btnCrearUsuario'), function (usuario) {
                    self.usuarios.push(usuario);
                    $('#crearUsuarioModal').modal('hide');
                    limpiarObjeto(self.crear);
                    toastr.success('El usuario ' + usuario.name + ' fue creado satisfactoriamente.', 'USUARIOS');
                });
            },

            editarUsuario: function (usuario) {
                this.editar.id = usuario.id;
                this.editar.nombre = usuario.name;
                this.editar.tipo = usuario.type;
                this.editar.email = usuario.email;
                this.editar.password = '';
                this.$els.editarUsuarioTitulo.textContent = usuario.name;
                this.temporal = usuario;
                $('#editarUsuarioModal').modal('show');
            },

            guardarUsuario: function () {
                var self = this;
                ajax('/plataforma/configuracion/usuarios/actualizar', 'json', self.editar, $('#btnEditarUsuario'), function (usuario) {
                    rellenarObjeto(self.temporal, usuario);
                    $('#editarUsuarioModal').modal('hide');
                    toastr.info('El usuario ' + usuario.name + ' fue editado satisfactoriamente.', 'USUARIOS');
                });
            },

            eliminarUsuario: function (usuario) {
                if (confirm('¿Seguro(a) qué desea eliminar el usuario ' + usuario.name + '?')) {
                    var self = this;
                    self.eliminar.id = usuario.id;
                    ajax('/plataforma/configuracion/usuarios/eliminar', 'html', self.eliminar, $('button[data-usuario-eliminar="' + usuario.id + '"]'), function (result) {
                        toastr.warning('El usuario ' + usuario.name + ' ha sido eliminado.', 'USUARIOS');
                        self.usuarios.$remove(usuario);
                    });
                }
            }
        }
    });

    $('#crearUsuarioModal').on('shown.bs.modal', function (event) { $('#crearNombre').focus(); });
    $('#editarUsuarioModal').on('shown.bs.modal', function (event) { $('#editarNombre').focus(); });
}
