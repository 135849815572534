if ($('#repertorios-publico').length > 0) {

    var vmRepertoriosPublico = new Vue({
        el: '#repertorios-publico',

        data: {
            canciones: [],
        },

        ready: function () {
            this.canciones = canciones;
        },

        computed: {
            cancionesOrdenadas: function () {
                return _.orderBy(this.canciones, ['numero'], ['asc']);
            }
        }
    });

    /*$(document).on('show.bs.collapse', '#acordionCanciones .collapse', function () {
        $(this).parent().find('[data-fa-i2svg]').toggleClass('fa-minus-circle').toggleClass('fa-plus-circle');
    });

    $(document).on('hide.bs.collapse', '#acordionCanciones .collapse', function () {
        $(this).parent().find('[data-fa-i2svg]').toggleClass('fa-plus-circle').toggleClass('fa-minus-circle');
    });*/
}
