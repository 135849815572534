if ($('#albumes').length > 0) {

    function ordenarTablas() {
        $('.tblCanciones tbody').sortable({
            helper: fixHelperModified,
            stop: function(event, ui) {
                var tabla = $(this).parent();
                ui.item.addClass('warning');
                var values = {};
                var i = 0;
                tabla.find('tbody').find('tr').each(function (index) {
                    values[i] = $(this).data('cancion-id');
                    i++;
                });
                var data = {
                    album_id: tabla.data('album-id'),
                    elements: values
                };
                ajax('/plataforma/website/canciones/ordenar', 'json', data, null, function(canciones) {
                    ui.item.removeClass('warning');
                    vmAlbumes.albumes.forEach(function (album) {
                        if (parseInt(album.id) === parseInt(tabla.data('album-id'))) {
                            album.canciones = canciones;
                        }
                    });
                    toastr.info('El orden de las canciones fue actualizado satisfactoriamente.', 'CANCIONES');
                });
            }
        }).disableSelection();
    }

    var vmAlbumes = new Vue({
        el: '#albumes',

        data: {
            albumes: albumes,
            album: {
                crear: { titulo: '', fecha: '', descripcion: '', playlist: '', publico: '' },
                editar: { id: '', titulo: '', fecha: '', descripcion: '', playlist: '', publico: '' },
                eliminar: { _method: 'DELETE', id: '' },
                temporal: {}
            },
            cancion: {
                crear: { album_id: '', titulo: '', versiculo: '', autores: '', youtube_id: '', audio: '', acordes: '' },
                editar: { _method: 'PUT', id: '', titulo: '', versiculo: '', autores: '', youtube_id: '', audio: '', acordes: '' },
                temporal: {},
                eliminar: { _method: 'DELETE', id: '' }
            }
        },

        ready: function () {
            ordenarTablas();
        },

        methods: {
            crearAlbum: function () {
                var self = this;

                var formData = new FormData();
                formData.append('titulo', self.album.crear.titulo);
                formData.append('fecha', self.album.crear.fecha);
                formData.append('descripcion', self.album.crear.descripcion);
                formData.append('playlist', self.album.crear.playlist);
                formData.append('portada', $('#crearAlbumPortada')[0].files[0]);
                formData.append('publico', self.album.crear.publico);

                ajax_archivo('/plataforma/website/albumes/almacenar', 'json', formData, $('#btnCrearAlbum'), function (album) {
                    self.albumes.unshift(album);
                    $('#crearAlbumModal').modal('hide');
                    limpiarObjeto(self.album.crear);
                    $('#crearAlbumPortada').val('');
                    toastr.success('El álbum ' + album.title + ' fue creado satisfactoriamente.', 'ÁLBUMES');
                    self.$nextTick(function () {
                        ordenarTablas();
                    });
                });
            },

            editarAlbum: function (album) {
                this.album.temporal = album;
                this.album.editar.id = album.id;
                this.album.editar.titulo = album.title;
                this.album.editar.fecha = album.date;
                this.album.editar.descripcion = album.description;
                this.album.editar.playlist = album.playlist;
                this.album.editar.publico = album.public;
                this.$els.editarAlbumTitulo.textContent = album.title;
                $('#editarAlbumModal').modal('show');
            },

            guardarAlbum: function () {
                var self = this;

                var formData = new FormData();
                formData.append('_method', 'PUT');
                formData.append('id', self.album.editar.id);
                formData.append('titulo', self.album.editar.titulo);
                formData.append('fecha', self.album.editar.fecha);
                formData.append('descripcion', self.album.editar.descripcion);
                formData.append('playlist', self.album.editar.playlist);
                formData.append('portada', $('#editarAlbumPortada')[0].files[0]);
                formData.append('publico', self.album.editar.publico);

                ajax_archivo('/plataforma/website/albumes/actualizar', 'json', formData, $('#btnEditarAlbum'), function (album) {
                    rellenarObjeto(self.album.temporal, album, ['canciones']);
                    $('#editarAlbumModal').modal('hide');
                    $('#editarAlbumPortada').val('');
                    toastr.info('El álbum ' + album.title + ' fue editado satisfactoriamente.', 'ÁLBUMES');
                });
            },

            eliminarAlbum: function (album) {
                if (confirm('¿Seguro(a) qué desea eliminar el álbum ' + album.title + '?\nTambién se eliminarán sus canciones.')) {
                    var self = this;
                    self.album.eliminar.id = album.id;
                    ajax('/plataforma/website/albumes/eliminar', 'html', self.album.eliminar, $('button[data-album-eliminar="' + album.id + '"]'), function (result) {
                        toastr.warning('El álbum ' + album.title + ' ha sido eliminado.', 'ÁLBUMES');
                        self.albumes.$remove(album);
                    });
                }
            },

            nuevaCancion: function (album) {
                this.album.temporal = album;
                this.cancion.crear.album_id = album.id;
                this.$els.crearCancionAlbumTitulo.textContent = album.title;
                $('#crearCancionModal').modal('show');
            },

            crearCancion: function () {
                var self = this;
                self.cancion.crear.acordes = CKEDITOR.instances['crearCancionAcordes'].getData();
                ajax('/plataforma/website/canciones/almacenar', 'json', self.cancion.crear, $('#btnCrearCancion'), function (cancion) {
                    self.album.temporal.canciones.push(cancion);
                    $('#crearCancionModal').modal('hide');
                    limpiarObjeto(self.cancion.crear);
                    CKEDITOR.instances['crearCancionAcordes'].setData('');
                    toastr.success('La canción ' + cancion.title + ' fue agregada satisfactoriamente al álbum ' + self.album.temporal.title, 'ÁLBUMES');
                });
            },

            editarCancion: function (albumTitulo, cancion) {
                this.cancion.editar.id = cancion.id;
                this.cancion.editar.titulo = cancion.title;
                this.cancion.editar.versiculo = cancion.verse;
                this.cancion.editar.autores = cancion.authors;
                this.cancion.editar.youtube_id = cancion.youtube_id;
                this.cancion.editar.audio = cancion.audio;
                CKEDITOR.instances['editarCancionAcordes'].setData(cancion.chords);
                this.$els.editarCancionTitulo.textContent = cancion.title;
                this.$els.editarCancionAlbumTitulo.textContent = albumTitulo;
                this.cancion.temporal = cancion;
                $('#editarCancionModal').modal('show');
            },

            guardarCancion: function () {
                var self = this;
                self.cancion.editar.acordes = CKEDITOR.instances['editarCancionAcordes'].getData();
                ajax('/plataforma/website/canciones/actualizar', 'json', self.cancion.editar, $('#btnEditarCancion'), function (cancion) {
                    rellenarObjeto(self.cancion.temporal, cancion);
                    $('#editarCancionModal').modal('hide');
                    toastr.info('La canción ' + cancion.title + ' fue editada satisfactoriamente.', 'ÁLBUMES');
                });
            },

            eliminarCancion: function (album, cancion) {
                if (confirm('¿Seguro(a) qué desea eliminar la canción ' + cancion.title + '?')) {
                    var self = this;
                    self.cancion.eliminar.id = cancion.id;
                    ajax('/plataforma/website/canciones/eliminar', 'json', self.cancion.eliminar, $('button[data-cancion-eliminar="' + cancion.id + '"]'), function (canciones) {
                        toastr.warning('La canción ' + cancion.title + ' ha sido eliminada.', 'ÁLBUMES');
                        album.canciones = canciones;
                    });
                }
            },
        }
    });

    $('#crearAlbumModal').on('shown.bs.modal', function (event) { $('#crearAlbumTitulo').focus(); });
    $('#crearAlbumFecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    $('#editarAlbumModal').on('shown.bs.modal', function (event) { $('#editarAlbumTitulo').focus(); });
    $('#editarAlbumFecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });

    $('#crearCancionModal').on('shown.bs.modal', function (event) { $('#crearCancionTitulo').focus(); });
    CKEDITOR.replace('crearCancionAcordes', { language: 'es' });
    $('#editarCancionModal').on('shown.bs.modal', function (event) { $('#editarCancionTitulo').focus(); });
    CKEDITOR.replace('editarCancionAcordes', { language: 'es' });
}
