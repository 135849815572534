if ($('#opciones').length > 0) {

    new Vue({
        el: '#opciones',

        data: {
            opciones: opciones,
            editar: { _method: 'PUT', youtube: '' }
        },

        methods: {
            editarOpciones: function () {
                this.editar.youtube = this.opciones.youtube;
                $('#editarOpcionesModal').modal('show');
            },

            guardarOpciones: function () {
                var self = this;
                ajax('/plataforma/website/opciones/actualizar', 'json', self.editar, $('#btnEditarOpciones'), function (resultado) {
                    self.opciones = resultado;
                    $('#editarOpcionesModal').modal('hide');
                    toastr.info('Las opciones fueron editadas satisfactoriamente.', 'OPCIONES');
                });
            }
        }
    });

    $('#editarOpcionesModal').on('shown.bs.modal', function (event) { $('#editarOpcionesYoutube').focus(); });

}
