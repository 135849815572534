if ($('#contacto').length > 0) {

    var vmContacto = new Vue({
        el: '#contacto',

        data: {
            datos: { nombre: '', email: '', mensaje: '' }
        },

        methods: {
            enviarMensaje: function () {
                var self = this;
                ajax('/contacto', 'html', self.datos, $('#btnContacto'), function () {
                    $('#contactoModal').modal('hide');
                    limpiarObjeto(self.datos);
                    toastr.success('Mensaje enviado satisfactoriamente.', 'CONTACTO');
                });
            }
        }
    });

    $('#contactoModal').on('shown.bs.modal', function () { $('#contactoNombre').focus(); });
}
