if ($('#musica').length > 0) {
    vmMusica = new Vue({
        el: '#musica',

        data: {
            albumes: albumes,
            album: { title: '', playlist: '' },
            cancion: { number: '', title: '', verse: '', authors: '', youtube_id: '', chords: '', audio: '' }
        },

        methods: {
            mostrarCancion: function (album, cancion) {
                rellenarObjeto(this.album, album);
                rellenarObjeto(this.cancion, cancion);
                $('#mostrarCancionModal').modal('show');
            }
        }
    });

    $('#mostrarCancionModal').on('hidden.bs.modal', function () {
        vmMusica.cancion.youtube_id = '';
        vmMusica.cancion.audio = '';
    });
}
