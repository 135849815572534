if ($('#repertorios-mostrar').length > 0) {

    function ordenarAcordion() {
        $('#acordionCanciones').sortable({
            stop: function(event, ui) {
                var acordion = $(this);
                ui.item.removeClass('panel-danger').addClass('panel-warning');
                var values = {};
                var i = 0;
                acordion.find('li').each(function (index) {
                    values[i] = $(this).data('cancion-id');
                    i++;
                });
                var data = {
                    repertorio_id: acordion.data('repertorio-id'),
                    elements: values
                };
                ajax('/plataforma/repertorios/canciones/arrastrar', 'json', data, null, function (canciones) {
                    ui.item.removeClass('panel-warning').addClass('panel-danger');
                    vmRepertoriosMostrar.canciones = canciones;
                });
            }
        }).disableSelection();
    }

    var vmRepertoriosMostrar = new Vue({
        el: '#repertorios-mostrar',

        data: {
            canciones: [],
            acordes: [],
            crear: {
                repertorio_id: '', titulo: '', contenido: '',
            },
            bloqueo_titulo: false,
            editar: {
                _method: 'PUT', id: '', titulo: '', contenido: '',
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: '', repertorio_id: '',
            },
        },

        ready: function () {
            this.canciones = canciones;
            this.acordes = acordes;
            this.crear.repertorio_id = repertorio_id;
            this.createTypeAheads();
            this.$nextTick(function () {
                ordenarAcordion();
            });
        },

        computed: {
            cancionesOrdenadas: function () {
                return _.orderBy(this.canciones, ['numero'], ['asc']);
            }
        },

        methods: {
            createTypeAheads: function () {
                var self = this;

                $('#crearCancionTitulo').typeahead({
                    source: self.acordes,
                    afterSelect: function (item) {
                        var datos = { titulo: item, solicitud: Math.random() };
                        self.bloqueo_titulo = true;
                        ajax('/plataforma/repertorios/canciones/busqueda', 'html', datos, null, function (cancion) {
                            self.bloqueo_titulo = false;
                            CKEDITOR.instances['crearCancionContenido'].setData(cancion);
                        });
                    }
                });

                $('#editarCancionTitulo').typeahead({
                    source: self.acordes,
                    afterSelect: function (item) {
                        var datos = { titulo: item, solicitud: Math.random() };
                        self.bloqueo_titulo = true;
                        ajax('/plataforma/repertorios/canciones/busqueda', 'html', datos, null, function (cancion) {
                            self.bloqueo_titulo = false;
                            CKEDITOR.instances['editarCancionContenido'].setData(cancion);
                        });
                    }
                });
            },

            crearCancion: function () {
                var self = this;
                self.crear.contenido = CKEDITOR.instances['crearCancionContenido'].getData();
                ajax('/plataforma/repertorios/canciones/almacenar', 'json', self.crear, $('#btnCrearCancion'), function (cancion) {
                    self.canciones.unshift(cancion);
                    $('#crearCancionModal').modal('hide');
                    limpiarObjeto(self.crear, ['repertorio_id']);
                    CKEDITOR.instances['crearCancionContenido'].setData('');
                    toastr.success('La canción "' + cancion.titulo + '" fue agregada satisfactoriamente.', 'REPERTORIOS');
                    self.$nextTick(function () {
                        ordenarAcordion();
                    });
                });
            },

            editarCancion: function (cancion) {
                rellenarObjeto(this.editar, cancion, ['_method', 'contenido']);
                this.editar.contenido = '';
                this.temporal = cancion;
                this.$els.editarCancionTitulo.textContent = cancion.titulo;
                CKEDITOR.instances['editarCancionContenido'].setData(cancion.contenido);
                $('#editarCancionModal').modal('show');
            },

            guardarCancion:  function () {
                var self = this;
                self.editar.contenido = CKEDITOR.instances['editarCancionContenido'].getData();
                ajax('/plataforma/repertorios/canciones/actualizar', 'json', self.editar, $('#btnEditarCancion'), function (cancion) {
                    rellenarObjeto(self.temporal, cancion);
                    $('#editarCancionModal').modal('hide');
                    toastr.info('La canción "' + cancion.titulo + '" fue actualizada satisfactoriamente.', 'REPERTORIOS');
                });
            },

            eliminarCancion: function (event, cancion) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar la canción "' + cancion.titulo + '" del repertorio?')) {
                    rellenarObjeto(self.eliminar, cancion, ['_method']);
                    ajax('/plataforma/repertorios/canciones/eliminar', 'json', self.eliminar, $(event.currentTarget), function (canciones) {
                        toastr.warning('La canción "' + cancion.titulo + '" fue eliminada satisfactoriamente.', 'REPERTORIOS');
                        self.canciones = canciones;
                    });
                }
            },

            ordenarCancion: function (event, cancion, direccion) {
                var self = this;
                var datos = { cancion_id: cancion.id, direccion: direccion, solicitud: Math.random() };
                ajax('/plataforma/repertorios/canciones/ordenar', 'json', datos, $(event.currentTarget), function (canciones) {
                    self.canciones = canciones;
                });
            },

            establecerBloqueo: function (numero, direccion) {
                console.log(numero);
            },
        }
    });

    $('#crearCancionModal').on('shown.bs.modal', function (event) {
        $('#crearCancionTitulo').focus();
    });
    CKEDITOR.replace('crearCancionContenido', { language: 'es' });

    $('#editarCancionModal').on('shown.bs.modal', function (event) {
        $('#editarCancionTitulo').focus();
    });
    CKEDITOR.replace('editarCancionContenido', { language: 'es' });

    /*$(document).on('show.bs.collapse', '#acordionCanciones .collapse', function () {
        $(this).parent().find('[data-fa-i2svg]').toggleClass('fa-minus-circle').toggleClass('fa-plus-circle');
    });

    $(document).on('hide.bs.collapse', '#acordionCanciones .collapse', function () {
        $(this).parent().find('[data-fa-i2svg]').toggleClass('fa-plus-circle').toggleClass('fa-minus-circle');
    });*/
}
