if ($('#recursosModal').length > 0) {

    var vmRecursos = new Vue({
        el: '#recursosModal',
        data: {
            estado: false,
            cartas: [],
            folletos: [],
            videos: [],
            password: ''
        },
        methods: {
            verificar: function () {
                var self = this;
                self.estado = false;
                self.cartas = [];
                self.folletos = [];
                self.videos = [];
                if (self.password == '#adoremos2016') {
                    self.cartas.push({ nombre: 'Carta para Pastores', url: '/recursos/carta_pastores_2016.docx' });
                    self.cartas.push({ nombre: 'Carta para Alcaldes', url: '/recursos/carta_alcaldes_2016.docx' });
                    self.cartas.push({ nombre: 'Carta para Asociación de Pastores', url: '/recursos/carta_asociaciones_2016.docx' });

                    self.folletos.push({ nombre: 'Folleto', url: '/recursos/folleto_adoremos_2016.pdf' });

                    self.videos.push({ nombre: 'Videos', url: '/recursos/videos_adoremos_2016.zip' });

                    self.$nextTick(function () {
                        self.estado = true;
                    });
                }
            }
        }
    });

    $('#recursosModal').on('shown.bs.modal', function () { $('#recursosContrasena').focus(); });
}
