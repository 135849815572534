if ($('#eventos').length > 0) {

    new Vue({
        el: '#eventos',

        data: {
            eventos: eventos,
            busqueda: { texto: '', ano: 0, mes: 0 },
            crear: { titulo: '', fecha: '', contenido: '' },
            editar: { id: '', titulo: '', fecha: '', contenido: '' },
            temporal: {},
            eliminar: { _method: 'DELETE', id: '' }
        },

        methods: {
            crearEvento: function () {
                var self = this;

                var formData = new FormData();
                formData.append('titulo', self.crear.titulo);
                formData.append('fecha', self.crear.fecha);
                formData.append('contenido', CKEDITOR.instances['txtCrearContenido'].getData());
                formData.append('imagen', $('#txtCrearImagen')[0].files[0]);

                ajax_archivo('/plataforma/website/eventos/almacenar', 'json', formData, $('#btnCrear'), function (resultado) {
                    self.eventos.unshift(resultado);
                    $('#crearEventoModal').modal('hide');
                    limpiarObjeto(self.crear);
                    CKEDITOR.instances['txtCrearContenido'].setData('');
                    $('#txtCrearImagen').val('');
                    toastr.success('El evento ' + resultado.titulo + ' fue creado satisfactoriamente.', 'EVENTOS');
                });
            },

            mostrarEvento: function (evento)
            {
                this.temporal = evento;
                rellenarObjeto(this.editar, evento);
                CKEDITOR.instances['txtEditarContenido'].setData(this.editar.contenido);
                $('#editarEventoModal').modal('show');
            },

            editarEvento: function ()
            {
                var self = this;

                var formData = new FormData();
                formData.append('_method', 'PUT');
                formData.append('id', self.editar.id);
                formData.append('titulo', self.editar.titulo);
                formData.append('fecha', self.editar.fecha);
                formData.append('contenido', CKEDITOR.instances['txtEditarContenido'].getData());
                formData.append('imagen', $('#txtEditarImagen')[0].files[0]);

                ajax_archivo('/plataforma/website/eventos/actualizar', 'json', formData, $('#btnEditar'), function (resultado) {
                    rellenarObjeto(self.temporal, resultado);
                    $('#editarEventoModal').modal('hide');
                    CKEDITOR.instances['txtEditarContenido'].setData('');
                    $('#txtEditarImagen').val('');
                    toastr.info('El evento ' + resultado.titulo + ' fue editado satisfactoriamente.', 'EVENTOS');
                });
            },

            eliminarEvento: function (evento) {
                if (confirm('¿Seguro(a) qué desea eliminar el evento ' + evento.titulo + '?')) {
                    var self = this;
                    self.eliminar.id = evento.id;
                    ajax('/plataforma/website/eventos/eliminar', 'html', self.eliminar, $('button[data-eliminar="' + evento.id + '"]'), function (result) {
                        toastr.warning('El evento ' + evento.titulo + ' fue eliminado satisfactoriamente.', 'EVENTOS');
                        self.eventos.$remove(evento);
                    });
                }
            },

            realizarBusqueda: function () {
                var self = this;
                ajax('/plataforma/website/eventos/busqueda', 'json', self.busqueda, $('#btnBusqueda'), function (resultado) {
                    self.eventos = resultado;
                    $('#busquedaModal').modal('hide');
                    toastr.info('Resultados: ' + resultado.length, 'EVENTOS');
                });
            },
        }
    });

    $('#busquedaModal').on('shown.bs.modal', function (event) { $('#txtBuscar').focus(); });

    $('#crearEventoModal').on('shown.bs.modal', function (event) { $('#txtCrearTitulo').focus(); });
    $('#txtCrearFecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    CKEDITOR.replace('txtCrearContenido', { language: 'es' });

    $('#editarEventoModal').on('shown.bs.modal', function (event) { $('#txtEditarTitulo').focus(); });
    $('#txtEditarFecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    CKEDITOR.replace('txtEditarContenido', { language: 'es' });

}
