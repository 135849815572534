//Vue.config.debug = true;

// DISABLE LOADING BUTTON
	$('.btn-bloquear').on('click', function (e) {
		var btn = $(this);
		btn.data('loading-text', '<img src="/img/' + btn.data('etiqueta') + '.gif"> ' + btn.text()).attr('autocomplete', 'off');
		btn.button('loading');
	});

// TOASTR OPTIONS
	toastr.options = {
	    "closeButton": false,
	    "debug": false,
	    "newestOnTop": false,
	    "progressBar": false,
	    "positionClass": "toast-bottom-left",
	    "preventDuplicates": false,
	    "onclick": null,
	    "showDuration": "300",
	    "hideDuration": "1000",
	    "timeOut": "5000",
	    "extendedTimeOut": "1000",
	    "showEasing": "swing",
	    "hideEasing": "linear",
	    "showMethod": "fadeIn",
	    "hideMethod": "fadeOut"
	}

// AJAX REQUESTS HELPER FUNCTION
	$.ajaxSetup({
		cache: false,
		headers: {
			'X-CSRF-TOKEN': $('meta[name=laravel-token]').attr("content")
		}
	});

	function ajax(url, tipo_datos, datos, boton, callback)
	{
		if (boton !== null) {
			if (boton.length > 0) {
				boton.data('loading-text', '<img src="/img/' + boton.data('etiqueta') + '.gif"> ' + boton.text()).attr('autocomplete', 'off');
				boton.button('loading');
			}
		}
		$.ajax({
			url: url,
			type: 'post',
			dataType: tipo_datos,
			data: datos,
			complete: function() {
				if (boton !== null) {
					if (boton.length > 0) {
						boton.button('reset');
					}
				}
			},
			success: callback,
			error: function(xhr, ajaxOption, thrownError) {
				console.log(thrownError);
				console.log(xhr.responseText);
				var errores = JSON.parse(xhr.responseText);
				var mensaje = 'Errores:\n\n';
				$.each(errores, function(key, value) {
					mensaje += (value + '\n');
				});
				alert(mensaje);
			}
		});
	}

	function ajax_archivo(url, tipo_datos, datos, boton, callback)
	{
		if (boton !== null) {
			if (boton.length > 0) {
				boton.data('loading-text', '<img src="/img/' + boton.data('etiqueta') + '.gif"> ' + boton.text()).attr('autocomplete', 'off');
				boton.button('loading');
			}
		}
		$.ajax({
			url: url,
			type: 'post',
			dataType: tipo_datos,
			processData: false,
			contentType: false,
			data: datos,
			complete: function() {
				if (boton !== null) {
					if (boton.length > 0) {
						boton.button('reset');
					}
				}
			},
			success: callback,
			error: function(xhr, ajaxOption, thrownError) {
				console.log(thrownError);
				console.log(xhr.responseText);
				var errores = JSON.parse(xhr.responseText);
				var mensaje = 'Errores:\n\n';
				$.each(errores, function(key, value) {
					mensaje += (value + '\n');
				});
				alert(mensaje);
			}
		});
	}

// HELPER FUNCTION TO KEEP TABLE ROW FROM COLLAPSING WHEN BEING SORTED
	var fixHelperModified = function(e, tr) {
		var $originals = tr.children();
		var $helper = tr.clone();
		$helper.children().each(function(index) {
		  $(this).width($originals.eq(index).width())
		});
		return $helper;
	};

// LIMPIAR OBJETO
    function limpiarObjeto(objeto, exclusiones)
    {
        for (var propiedad in objeto) {
            if (typeof exclusiones == 'undefined') {
                objeto[propiedad] = '';
            } else {
                var resultado = false;
                exclusiones.forEach(function (exclusion) {
                    if (propiedad == exclusion) {
                        resultado = true;
                    }
                });
                if (!resultado) {
                    objeto[propiedad] = '';
                }
            }
        }
    }

// RELLENAR OBJETO
	function rellenarObjeto(objeto_a_rellenar, objeto_de_donde_rellenar, exclusiones)
	{
		for (var propiedad in objeto_a_rellenar) {
			if (typeof exclusiones == 'undefined') {
				objeto_a_rellenar[propiedad] = objeto_de_donde_rellenar[propiedad];
			} else {
				var resultado = false;
				exclusiones.forEach(function(exclusion) {
					if (propiedad == exclusion) {
						resultado = true;
					}
				});
				if (!resultado) {
					objeto_a_rellenar[propiedad] = objeto_de_donde_rellenar[propiedad];
				}
			}
		}
	}

// HIDE MODALS ON BROWSER BACK BUTTON
	$(document).off('show.bs.modal', 'div.modal').on('show.bs.modal', 'div.modal', function() {
		var modal = this;
		var hash = 'modal';
		window.location.hash = hash;
		window.onhashchange = function() {
			if (!location.hash){
				$(modal).modal('hide');
			}
		}
	});

	$(document).off('hide.bs.modal', 'div.modal').on('hide.bs.modal', 'div.modal', function() {
		var hash = 'modal';
		history.pushState('', document.title, window.location.pathname);
	});
