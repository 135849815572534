// PASSWORD CHANGE
if ($('#cambio-contrasena').length > 0) {
    $('#passwordModal').on('shown.bs.modal', function () {
        $('#contrasena_actual').focus();
    });

    new Vue({
        el: '#cambio-contrasena',

        data: {
            password: {
                actual: '',
                nueva: '',
                confirme: ''
            }
        },

        methods: {
            guardarContrasena: function()
            {
                var self = this;
                ajax('/plataforma/tablero/contrasena', 'html', self.password, $('#btnPassword'), function(resultado) {
                    $('#passwordModal').modal('hide');
                    self.password.actual = '';
                    self.password.nueva = '';
                    self.password.confirme = '';
                    toastr.info('La contraseña fue cambiada satisfactoriamente.', 'PLATAFORMA');
                });
            }
        }
    });
}
