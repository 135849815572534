if ($('#repertorios').length > 0) {

    var vmRepertorios = new Vue({
        el: '#repertorios',

        data: {
            repertorios: [],
            crear: {
                titulo: '', fecha: '', descripcion: '', etiqueta: '',
            },
            editar: {
                _method: 'PUT', id: '', titulo: '', fecha: '', descripcion: '', etiqueta: '',
            },
            temporal: '',
            eliminar: {
                _method: 'DELETE', id: '',
            },
        },

        ready: function () {
            this.repertorios = repertorios;
        },

        methods: {
            crearRepertorio: function () {
                var self = this;
                ajax('/plataforma/repertorios/almacenar', 'json', self.crear, $('#btnCrearRepertorio'), function (repertorio) {
                    window.location.href = '/plataforma/repertorios/' + repertorio.id;
                    return false;
                });
            },

            editarRepertorio: function (repertorio) {
                this.temporal = repertorio;
                rellenarObjeto(this.editar, repertorio, ['_method']);
                this.$els.editarRepertorioTitulo.textContent = repertorio.titulo;
                $('#editarRepertorioModal').modal('show');
            },

            guardarRepertorio: function () {
                var self = this;
                ajax('/plataforma/repertorios/actualizar', 'json', self.editar, $('#btnEditarRepertorio'), function (repertorio) {
                    rellenarObjeto(self.temporal, repertorio, ['canciones']);
                    $('#editarRepertorioModal').modal('hide');
                    toastr.info('El repertorio "' + repertorio.titulo + '" fue actualizado satisfactoriamente.', 'REPERTORIOS');
                });
            },

            eliminarRepertorio: function (event, repertorio) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar el repertorio "' + repertorio.titulo + '"?\nTambién se eliminarán sus canciones.')) {
                    self.eliminar.id = repertorio.id;
                    ajax('/plataforma/repertorios/eliminar', 'html', self.eliminar, $(event.currentTarget), function () {
                        toastr.warning('El repertorio "' + repertorio.titulo + '" ha sido eliminado.', 'REPERTORIOS');
                        self.repertorios.$remove(repertorio);
                    });
                }
            },

            mostrarRepertorio: function (repertorio) {
                window.location.href = '/plataforma/repertorios/' + repertorio.id;
                return false;
            },
        }
    });

    $('#crearRepertorioModal').on('shown.bs.modal', function (event) {
        $('#crearRepertorioTitulo').focus();
    });

    $('#editarRepertorioModal').on('shown.bs.modal', function (event) {
        $('#editarRepertorioTitulo').focus();
    });
}
